<template>
    <div class="d-flex h-100" tabindex="1" id="portal-parent-view">
        <div class="d-flex w-100 h-100" v-if="isRefreshingToken">
            <div class="d-inline-flex align-self-center m-auto">
                <div class="text-center">
                    <div>
                        <img style="width: 60px" src="/pulse.gif"/>
                    </div>
                    <div>
                        Checking you are securely logged in.
                    </div>
                    <div>
                        Please wait...
                    </div>
                </div>
            </div>
        </div>

      <div v-else-if="!isRefreshingToken" class="wrapper">

        <div id="sidebar">
          <gro-sidebar class="h-100">

            <div slot="default" class="d-flex flex-column h-100">
              <router-link :to="{name: 'portal.dashboard'}" class="d-flex sidebar-item">
                <div class="d-inline-flex sidebar-title">
                  <i class="material-icons d-flex mr-3">home</i>
                  Dashboard
                </div>
              </router-link>
              <router-link :to="{name: 'portal.referrals'}" class="d-flex sidebar-item">
                <div class="d-inline-flex sidebar-title">
                  <i class="material-icons d-flex mr-3">group</i>
                  Referrals
                </div>
              </router-link>
              <div @click="$store.dispatch('Auth/logout')" class="d-flex sidebar-item logout">
                <div class="d-inline-flex sidebar-title">
                  <i class="material-icons d-flex mr-3">logout</i>
                  Log out
                </div>
              </div>
            </div>
          </gro-sidebar>
        </div>
        <div id="content" class="pb-4">
          <gro-app-header height="60px">
            <img src="@/assets/logo.svg" style="display: block;margin: 0 auto;" height="36" alt=""/>
          </gro-app-header>
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>
</template>
<script>

    import {eventBus} from "../../helpers/eventBus";


    import {
        FAILED_TO_REFRESH_VIOLATION,
        handleRefreshToken, INAPPROPRIATE_TO_REFRESH_VIOLATION,
        NO_ACCESS_TOKEN_VIOLATION
    } from "@/helpers/handleRefreshToken";

    export default {
        name: "PortalParentView",
        data() {
                return {
                  isRefreshingToken: false
                }
        },
        methods: {
            handleRefreshToken() {
              this.isRefreshingToken = true;
                handleRefreshToken(true).then(async (res) => {
                    this.isRefreshingToken = false;
                    await this.$store.dispatch('User/getUser');
                }).catch(async (err) => {
                    switch (err) {
                        case INAPPROPRIATE_TO_REFRESH_VIOLATION:
                            break;
                        case NO_ACCESS_TOKEN_VIOLATION:
                        case FAILED_TO_REFRESH_VIOLATION:
                            await this.$router.push({
                              path: 'Login'
                            })
                            break;
                        default:
                            this.isRefreshingToken = false;
                            break;
                    }
                });
            }
        },
        async mounted() {
            this.handleRefreshToken();
        }
    }
</script>
<style lang="scss">
    body {
        overflow: hidden;
    }

    .logout {
      cursor: pointer;
      margin-top: auto!important;
    }

    #portal-parent-view {
      overflow: scroll;
    }

    .txt-search {
        border-radius: 4px;
        border: solid 1px #c8c7cc;
        background-color: #ffffff;
        width: 100%;
        padding: 12px;
        box-sizing: border-box;
    }

    #app {
        position: absolute;
        height: 100%;
        width: 100%;
    }
    .borderedButton {
      border: 1px solid #c8c7cc;
      padding-top: 10px;
      cursor:pointer;
    }
    .buttonWidth{
      padding-left: 140px;
      padding-bottom: 15px;
    }

    .sidebar-item {
      padding: 16px 21.7px 16px 14px;
      color: #062940!important;
      margin: 4px 6px;
      text-decoration: none!important;
      z-index: 1;
      &.router-link-active {
        position: relative;
        &:before {
          border-radius: 12px;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: .1;
          z-index: -1;
          background: rgb(0,120,248);
          background: linear-gradient(90deg, rgba(0,120,248,1) 0%, rgba(177,52,255,1) 35%);
        }
      }
    }
</style>